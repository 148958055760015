// ====================
// Interfaces
// ====================

export interface ConfigWebPage {
  home: HomeConfig
  aboutUs: AboutUsConfig
  contact: FormConfig
  stock: SectionConfig
  renewal: FormConfig
  purchase: FormConfig
  financing: FormConfig
  consignment: FormConfig
  privacyPolicy: PrivacyPolicyConfig
  corporateIdentity: CorporateIdentityConfig
  termsAndConditions: TermsAndConditionsConfig
}

// ====================
// Additional Classes for Interfaces
// ====================

export class SectionConfigClass implements SectionConfig {
  seo: SectionConfig;
  title: string;
  subtitle: string;
  alignment?: string;
  display?: {
    soldAfterDays: number
    sold: boolean
    enablement: boolean
    enablementWithOutPhoto: boolean
    enablementWithOutPrice: boolean
    availableWithOutPhoto: boolean
    availableWithOutPrice: boolean
  }

  extraButton?: {
    display: boolean
    title: string
    link: string
    background: string
    text: string
  }

  insurances?: Array<{
    id: number
    link: string
  }>;

  financials?: Array<{
    id: number
    link: string
  }>;

  associated?: Array<{
    id: number
    link: string
  }>;
}

export class SectionConfigWithVisibilityClass extends SectionConfigClass implements SectionConfigWithVisibility {
  visible: boolean;
}

export class ScheduledSectionClass implements ScheduledSection {
  title: string;
  subtitle: string;
  alignment?: string;
}

export class HomeConfigClass implements HomeConfig {
  seo: SectionConfigClass;
  team: SectionConfigClass;
  stock: SectionConfigClass;
  clients: SectionConfigWithVisibilityClass;
  location: SectionConfigClass;
  scheduled: ScheduledSectionClass;
  partners: SectionConfigClass;
}

export class HistoryClass implements History {
  date: string;
  icon: string;
  title: string;
  description: string;
}

export class MilestoneClass implements Milestone {
  date: string;
  icon: string;
  title: string;
  description: string;
  showOnWebsite: boolean;
}

export class CultureAndValuesClass implements CultureAndValues {
  subtitle: string;
  description: string;
}

export class AboutUsConfigClass implements AboutUsConfig {
  seo: SectionConfigClass;
  slogan: string;
  history: HistoryClass;
  visible: boolean;
  milestones: MilestoneClass[];
  showHistory: boolean;
  companyTitle: string;
  backgroundImage: string;
  sectionSubtitle: string;
  cultureAndValues: CultureAndValuesClass;
  showCultureAndValues: boolean;
}

export class ConfirmationConfigClass implements ConfirmationConfig {
  title: string;
  message: string;
  subtitle: string;
}

export class ServiceConfigClass implements FormConfig {
  seo: SectionConfigClass;
  title: string;
  subtitle: string;
  confirmation: ConfirmationConfigClass;
}

export class PolicySectionClass implements PolicySection {
  title: string;
  description: string;
}

export class PrivacyPolicyConfigClass implements PrivacyPolicyConfig {
  title: string;
  content: {
    section1: PolicySectionClass
    section2: PolicySectionClass
    section3: PolicySectionClass
    section4: PolicySectionClass
    section5: PolicySectionClass
    section6: PolicySectionClass
  };

  description: string;
}

export class TermsAndConditionsConfigClass implements TermsAndConditionsConfig {
  title: string;
  content: {
    section1: PolicySectionClass
    section2: PolicySectionClass
    section3: PolicySectionClass
    section4: PolicySectionClass
    section5: PolicySectionClass
    section6: PolicySectionClass
    section7: PolicySectionClass
    section8: PolicySectionClass
  };

  description: string;
}

export class ColorsClass implements Colors {
  info: string;
  error: string;
  primary: string;
  success: string;
  warning: string;
  secondary: string;
  background: string;
  text: string;
  header: {
    background: string
    text: string
  }

  card: {
    background: string
    text: string
    icon: string
  }

  footer: {
    background: string
    text: string
  }

  confirm: {
    background: string
    text: string
    icon: string
  }
}

export class CorporateIdentityConfigClass implements CorporateIdentityConfig {
  favicon: string;
  companyLogo: string | null;
  facadeImage: string | null;
  facadeHeight: number;
  facadeHeightMobile: number;
}

export class StyleConfigClass implements StyleConfig {
  colors: ColorsClass;
  buttonStyle: {
    value: string
    description: string
    rounded: string
    elevation: number
  }

  cardStyle: {
    rounded: string
    elevation: number
  }

  font: {
    family: string
    weight: string
  }
}

export interface HomeConfig {
  seo: SectionConfig
  team: SectionConfig
  stock: SectionConfig
  clients: SectionConfigWithVisibility
  location: SectionConfig
  scheduled: ScheduledSection
}

export interface SectionConfig {
  title: string
  subtitle: string
  alignment?: string
}

export interface SectionConfigWithVisibility extends SectionConfig {
  visible: boolean;
}

export interface ScheduledSection {
  title: string
  subtitle: string // Se mantiene el nombre de propiedad según el JSON
}

export interface AboutUsConfig {
  slogan: string
  history: History
  visible: boolean
  milestones: Milestone[]
  showHistory: boolean
  companyTitle: string
  backgroundImage: string
  sectionSubtitle: string
  cultureAndValues: CultureAndValues
  showCultureAndValues: boolean
}

export interface History {
  date: string
  icon: string
  title: string
  description: string
}

export interface Milestone {
  date: string
  icon: string
  title: string
  description: string
  showOnWebsite: boolean;
}

export interface CultureAndValues {
  subtitle: string;
  description: string;
}

export interface FormConfig {
  title: string
  subtitle: string
  confirmation: ConfirmationConfig
  seo: SectionConfig
}

export interface ConfirmationConfig {
  title: string
  message: string
  subtitle: string
}

export interface PrivacyPolicyConfig {
  title: string
  content: {
    section1: PolicySection
    section2: PolicySection
    section3: PolicySection
    section4: PolicySection
    section5: PolicySection
    section6: PolicySection
  }
  description: string
}

export interface TermsAndConditionsConfig {
  title: string
  content: {
    section1: PolicySection
    section2: PolicySection
    section3: PolicySection
    section4: PolicySection
    section5: PolicySection
    section6: PolicySection
    section7: PolicySection
    section8: PolicySection
  }
  description: string
}

export interface PolicySection {
  title: string
  description: string
}

export interface CorporateIdentityConfig {
  favicon: string
  companyLogo: string | null
  facadeImage: string | null
  facadeHeight: number
  facadeHeightMobile: number
}

export interface StyleConfig {
  colors: Colors
  buttonStyle: {
    value: string
    description: string
    rounded: string
    elevation: number
  }
  cardStyle: {
    rounded: string
    elevation: number;
  }
  font: {
    family: string
    weight: string;
  }
}

export interface Colors {
  info: string
  error: string
  primary: string
  success: string
  warning: string
  secondary: string
  background: string
  footer: {
    background: string
    text: string
  }
  header: {
    background: string
    text: string
  }
  card: {
    background: string
    text: string
  }
  confirm: {
    background: string
    text: string
    icon: string
  }
}

// ====================
// Clases
// ====================

export class ConfigWebPageClass implements ConfigWebPage {
  home: HomeConfigClass;
  stock: SectionConfigClass;
  aboutUs: AboutUsConfigClass;
  contact: ServiceConfigClass;
  renewal: ServiceConfigClass;
  purchase: ServiceConfigClass;
  financing: ServiceConfigClass;
  consignment: ServiceConfigClass;
  privacyPolicy: PrivacyPolicyConfigClass;
  corporateIdentity: CorporateIdentityConfigClass;
  termsAndConditions: TermsAndConditionsConfigClass;
  style: StyleConfigClass;
}
